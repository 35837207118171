.App {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e3e3e3;
}
.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  color: #999;
  text-align: center;
  padding: 15px 0;
  font-size: 12px;
  background-color: white;
}
.logo {
  width: 500px;
  animation: App-logo-spin infinite 20s linear;
}
a {
  color: #777;
  text-decoration: none;
}
